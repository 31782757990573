<template>
  <div>
    <br>
    <h5>Background Description</h5>
    <p class="big mt-2">
      The sum of interior angles in a quadrilateral is always \(360^o\).
    </p>
    <div style="text-align:left">
      <h5> MagicGraph | Sum of Interior Angles of a Quadrilateral </h5>
    </div>
    <p class="big mt-2">
      This MagicGraph offers a visually interactive demonstration of interior angle theorem for quadrilaterals. You are given a quadrilateral <i>ABCD</i>.<br>
      <i>A</i>, <i>B</i>, <i>C</i> and <i>D</i> are the four vertices of this quadrilateral. These vertices can be moved around. Further, <i>a</i>, <i>b</i>, <i>c</i> and <i>d</i> are the four interior angles of this quadrilateral.
      <br>
      By moving the four vertices around, you can change the quadrilateral. As you move the vertices around, you will notice that sum of four interior angles <i>a+b+c+d</i> always remains \(360^o\).
    </p>
    <v-responsive>
      <v-layout justify-center>
        <div id="jxgbox1" class="edliy-box-about" />
      </v-layout>
    </v-responsive>
    <br>
  </div>
</template>
<script>
import Boxes from './Boxes.js'
export default {
  name: 'TrigonometricRatio',
  created: function () {
    this.$store.commit('navigation/resetState');
    let title = 'Quadrilateral';
    this.$store.commit('navigation/changeTitle', title);
  /* this.$store.commit('navigation/changeMenu', title);
    let newTopics = [
      {title: 'Probability', img:'/assets/number-1.svg', action: () => this.goto('intro')},
      {title: 'Probability of an Event', img:'/assets/number-2.svg', action: () => this.goto('types')},
      {title: 'MagicGraph',img:'/assets/touch.svg', action: () => this.goto('pg')},
    ];
    this.$store.commit('navigation/replaceTopics', newTopics);
*/
    let newshowhome = false;
    this.$store.commit('navigation/toggleshowhome', newshowhome);
    let newMath =true;
    this.$store.commit('navigation/replaceMath', newMath);
    let newLeftArrow =true;
    this.$store.commit('navigation/replaceLeftArrow', newLeftArrow);
    let newModule=true;
    this.$store.commit('navigation/replaceModule', newModule);
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box1();
  },
  metaInfo() {
  return{ title: 'Quadrilateral',
          titleTemplate: '%s | Learn interactively',
          meta: [
                  {vmid: 'description', name: 'description', content: 'Learn interactively about trigonometric ratios.'}
                ]
        }
   },
}
</script>
