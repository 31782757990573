import {
  makeResponsive,
  placeTitle,
  placeImage,
  placeInput,
  placeSlider,
  hoverMe,
  placeRec,
  hiddenPt,
  fixedPt,
  clearInputFields,
  dragMe,
  placeArrow,
  placeGravity,
  placeText,
  placeMiddleText,
  placeLine,
  placePoint,
  placeGlider,
  placeRuler,
  placeLeftText,
  placeCircle,
  placeAngle,
  placeDash,
  placeLabel,
  placeRightText,
//placePoint(board, positionX, positionY, size, cols1, cols2)
//placeDash(board, Pt1, Pt2, width, cols)
  placeArc,
  placeLogo,
  placeErase,
  placeShuffle,
  placeTest,
  placeSeg,
  setConfig,
  placeStat,
  placeTri,
  shuffle,
  placeEraseLeft,
  placeEraseRight,
  createSpace,
  placeStartOver,
  writeHTMLText,
  placeBoldText,
  drawPoint,
  setSize
} from '../Utils';
const Boxes = {
  box1: function () {
    var brd1 = createSpace(-6, 6, -4, 8);
    brd1.options.board.minimizeReflow = 'none';
    brd1.options.point.showInfobox =false;
    brd1.options.line.highlight=false;
    brd1.options.image.highlight=false;
    brd1.options.text.highlight=false;
    brd1.options.polygon.highlight=false;
        makeResponsive(brd1);
        placeLogo(brd1);
        placeTitle(brd1, 'Sum of Interior Angles in a Quadrilateral', '(Points A, B, C and D are draggable)');
      //
    var i=0; var j=0;
    var Pt1 = brd1.create('point', [-2,2],{name:'A', size:4, fillColor:'white', strokeColor:'black', snapToGrid:true, label: {offset:[-15, 15], fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
    //
    var Pt2 = brd1.create('point', [2,3],{name:'B ', size:4, fillColor:'white',strokeColor:'black', snapToGrid:true, label: {offset:[15, 15],fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
    //
    var Pt3 = brd1.create('point', [2-1,-1],{name:'C', size:4,fillColor:'white',  strokeColor:'black', snapToGrid:true, label: {offset:[15, -15],fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
    //
    var Pt4 = brd1.create('point', [-3,-2],{name:'D', size:4,fillColor:'white',  strokeColor:'black', snapToGrid:true,label: {offset:[-15, -15],fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
    /////////////Point#1///////////////
    Pt1.on('down', function(){i=1});
    Pt1.on('up', function(){i=0});
    /////////////Point#2///////////////
    Pt2.on('down', function(){i=1});
    Pt2.on('up', function(){i=0});
    /////////////Point#3///////////////
    Pt3.on('down', function(){i=1});
    Pt3.on('up', function(){i=0});
    /////////////Point#4///////////////
    Pt4.on('down', function(){i=1});
    Pt4.on('up', function(){i=0});
    //brd1.create('segment', [Pt1, Pt3], {strokeColor:'blue', strokeWidth:1, fixed:true});

    brd1.create('polygon', [Pt1, Pt2, Pt3, Pt4],{fixed:true, strokeWidth:2, fillColor:'white', fillOpacity:1, highlight:false, borders:{fixed:true, strokeColor:'black', strokeWidth:3, highlight:false}});

	  var ang1 =brd1.create('angle', [Pt1, Pt2, Pt3],{name:'a', radius:1, strokeColor:'blue', fillColor:'blue',  label:{color:'blue', fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});

    var ang2 = brd1.create('angle', [Pt2, Pt3, Pt4],{name:'b', radius:1, strokeColor:'purple', fillColor:'purple', label:{color:'purple', fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});

    var ang3 = brd1.create('angle', [Pt3, Pt4, Pt1],{name:'c', radius:1, strokeColor:'red', fillColor:'red', label: {color:'red', fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});

    var ang4 = brd1.create('angle', [Pt4, Pt1, Pt2],{name:'d', radius:1, strokeColor:'green', fillColor:'green',visible:function(){if(j==0){return true}else{return false}}, label: {color:'green',fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
    //
    brd1.create('text', [-3, 5.5, function(){return 'a = '+ (ang1.Value()*180/Math.PI).toFixed(2) + '^o'}],{color:'blue', anchorX:'left', highlight:false, CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(14*brd1.canvasWidth/500.)}, fixed:true, highlight:false});

    brd1.create('text', [-1.25, 5.5, function(){return 'b = '+ (ang2.Value()*180/Math.PI).toFixed(2)+ '^o'}],{color:'blue', anchorX:'left', highlight:false, CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(14*brd1.canvasWidth/500.)}, fixed:true});

    brd1.create('text', [0.5, 5.5, function(){return 'c = '+ (ang3.Value()*180/Math.PI).toFixed(2) + '^o'}],{color:'blue', anchorX:'left', highlight:false, CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(14*brd1.canvasWidth/500.)},fixed:true});

    brd1.create('text', [2.25, 5.5, function(){return 'd = '+ (ang4.Value()*180/Math.PI).toFixed(2) + '^o'}],{color:'blue', anchorX:'left', highlight:false, CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(14*brd1.canvasWidth/500.)},fixed:true});

    brd1.create('text', [-3, 4.5, function(){return 'a + b + c + d = '+ (ang1.Value()*180/Math.PI).toFixed(2) +'^o + ' + (ang2.Value()*180/Math.PI).toFixed(2)+'^o + '+(ang3.Value()*180/Math.PI).toFixed(2) + '^o +' + (ang4.Value()*180/Math.PI).toFixed(2)+'^o = '+
  ((ang1.Value()+ang2.Value()+ang3.Value()+ang4.Value())*180/Math.PI).toFixed(0)+'^o ' }],{visible:true, color:'blue',  anchorX:'left', anchorY:'middle', highlight:false, CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(14*brd1.canvasWidth/500.)},fixed:true});

  var txt =writeHTMLText(brd1, 0, -3.5, 'Sum always remains 360^0');
  //txt.setAttribute({color:'blue', visible:()=>i==1});
    }
}
export default Boxes;
